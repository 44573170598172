import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useGeoLocation, useVertical } from 'lib/Hooks';
import { useMyNewsStore } from 'store';

import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { MYNEWS_ENABLED } from 'lib/brandFeatures';
import { getAuthenticationState } from 'lib/authenticationUtils';

import Link from 'components/Link';
import { Save } from 'components/SocialShareMenu/Save';

import {
  ARTICLE,
  VIDEO,
  RECIPE,
  PRODUCT,
  AUTHENTICATED,
  UNAUTHENTICATED,
} from 'lib/myNewsConstants';

import styles from './styles.module.scss';


const unsaveText = '';

/**
 * @typedef {object} BookmarkButtonProps
 * @property {string} contentId
 * @property {ARTICLE|VIDEO|RECIPE|PRODUCT} contentType
 * @property {string} [pageRegion='']
 * @property {boolean} [isChromeless=false]
 */

/**
 * @type {React.FunctionComponent<BookmarkButtonProps>}
 */
export function BookmarkButton({
  contentId,
  contentType,
  pageRegion = '',
  isChromeless = false,
}) {
  const [message, setMessage] = useState(unsaveText);
  const [showMyNews, setShowMyNews] = useState(false);
  const authenticationState = getAuthenticationState();
  const bookmarkList = useMyNewsStore((state) => state.bookmarks);

  const authText = `Create your free account \nor log in to save this ${contentType}`;
  const saveText = `Save this ${contentType} \nto ${contentType === VIDEO ? 'watch' : 'read'} later`;

  const { isUsa } = useGeoLocation();
  const { vertical } = useVertical();

  const isAuthenticated = authenticationState === AUTHENTICATED;
  const isUnauthenticated = authenticationState === UNAUTHENTICATED;
  const isMyNewsEnabled = Boolean(getFeatureConfigForBrand(MYNEWS_ENABLED, vertical));

  const shouldRender = !isChromeless && isUsa && contentId && isMyNewsEnabled;
  const shouldShow = isAuthenticated || isUnauthenticated;

  const anchorId = contentType === 'article' ? '#saved' : `#saved-${contentType}s`;

  const LinkToSaved = (
    <Link to={`/account${anchorId}`} className={styles.myNewsLink} data-testid="bookmark-button__link">
        Go to
      <br />
        Saved
    </Link>
  );

  useEffect(() => {
    const isBookmarked = bookmarkList?.[contentId];
    // We only want to show the bookmark button message
    // if we know whether or not the content is bookmarked when Authenticated

    setShowMyNews(isBookmarked);
    if (isUnauthenticated) setMessage(authText);
    if (isAuthenticated && isBookmarked) setMessage(unsaveText);
    if (isAuthenticated && isBookmarked === false) setMessage(saveText);
    if (isAuthenticated && isBookmarked === undefined) setMessage('');
  }, [bookmarkList, authenticationState, contentId]);

  if (!shouldRender) return null;

  const handleSave = () => {
    setMessage(unsaveText);
    setShowMyNews(true);
  };
  const handleRemove = () => {
    setMessage(saveText);
    setShowMyNews(false);
  };

  return (
    <div className={`bookmark-button__container ${styles.bookmarkButton} dn-print`} data-testid="bookmark-button">
      <div className={styles.bookmarkButtonWrapper}>
        <div className={classNames(styles.backgroundRibbon, { [styles.shouldShow]: shouldShow })} />
        <Save
          contentId={contentId}
          contentType={contentType}
          callbacks={{
            save: handleSave,
            remove: handleRemove,
          }}
          options={{ pageRegion }}
        />
      </div>
      <span className={styles.message} data-testid="bookmark-button__message">
        {showMyNews ? (LinkToSaved) : message}
      </span>
    </div>
  );
}

BookmarkButton.propTypes = {
  contentId: PropTypes.string.isRequired,
  contentType: PropTypes.oneOf([
    ARTICLE,
    VIDEO,
    RECIPE,
    PRODUCT,
  ]).isRequired,
  pageRegion: PropTypes.string,
  isChromeless: PropTypes.bool,
};
