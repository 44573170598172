import React, {
  useState, useContext, useCallback, useMemo,
} from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';

import ArticleHeroHeadline from 'components/Article/ArticleHero/ArticleHeroHeadline';
import ArticleHeroFlag from 'components/Article/ArticleHero/ArticleHeroFlag';
import Sponsored from 'components/Sponsored';
import { Unibrow } from 'components/Unibrow';
import TodayLogoForRecipePrint from 'components/Recipe/TodayLogoForRecipePrint';
import Timestamp from 'components/Recipe/Timestamp';

import {
  getMedia,
  getMediaPresentationStyle,
  getDataAuthors,
  renderCaption,
  renderDek,
} from 'lib/articleUtils';
import { isShellArticle, isMSNBCDaily } from 'lib/article';
import { FeatureFlagContext } from 'lib/ContextTypes';
import {
  primaryMedia as primaryMediaPropType,
  socialMediaProfiles as socialPropType,
  heroLayoutType as heroLayoutTypePropType,
} from 'lib/CustomPropTypes';
import { sectionContainSlug } from 'lib/taxonomy';
import Breakpoints from 'lib/Breakpoints';
import { VIEW } from 'lib/view';
import './styles.themed.scss';

const block = 'article-hero';

/**
 * Maps the state from the Redux store to the component's props.
 *
 * @param {object} state - The state from the Redux store.
 * @param {object} state.shared - The shared state.
 * @param {object} state.tve - The TVE state.
 * @param {object} state.video - The video state.
 * @returns {object} The mapped props.
 */
const mapStateToProps = ({ shared, tve, video }) => ({
  socialMediaProfiles: shared.socialMediaProfiles,
  authenticated: tve.authenticated,
  hasTempPass: tve.hasTempPass,
  provider: tve.provider,
  videoError: video.error,
  view: shared.view,
});

// for SEO purposes, we only want the disclaimer rendered in the client and not SSR'ed
const DynamicDisclaimerDefault = dynamic(
  async () => {
    // need to access the named export DisclaimerDefault
    const { DisclaimerDefault } = await import('components/Disclaimer');
    return DisclaimerDefault;
  },
  { ssr: false },
);

/**
 * @typedef HeroLayoutType
 * @property {boolean} default
 * @property {boolean} wide
 */

/**
 * ArticleHero component to display the hero section of an article.
 *
 * @param {object} props - The properties object.
 * @param {object} props.additionalClasses - Additional classes for styling.
 * @param {object} props.content - The content data for the article.
 * @param {boolean} [props.isChromeless] - Indicates if the article is chromeless.
 * @param {boolean} [props.isLiveBlog] - Indicates if the article is a live blog.
 * @param {boolean} [props.isRecipe] - Indicates if the article is a recipe.
 * @param {boolean} [props.isPresentationStyleWide] - Indicates if the presentation style is wide.
 * @param {string} [props.path] - The path of the article.
 * @param {boolean} [props.primaryMediaBannerEnabled] - Indicates if the primary media banner is enabled.
 * @param {object} props.socialMediaProfiles - The social media profiles.
 * @param {React.Element} props.stylizedTag - The stylized tag element.
 * @param {string} props.vertical - The vertical of the article.
 * @param {HeroLayoutType} [props.heroLayoutType] - The layout type of the hero section.
 * @param {boolean} props.authenticated - Indicates if the user is authenticated.
 * @param {boolean} props.hasTempPass - Indicates if the user has a temporary pass.
 * @param {boolean} props.provider - Indicates if the provider is available.
 * @param {string|boolean} props.videoError - The video error.
 * @param {boolean} props.isShoppable - Indicates if the article is shoppable.
 * @param {string} props.view - The view of the article.
 */
const ArticleHero = ({
  additionalClasses = { headline: {} },
  content,
  isChromeless = false,
  isLiveBlog = false,
  isRecipe = false,
  isPresentationStyleWide = false,
  path = '',
  primaryMediaBannerEnabled = false,
  socialMediaProfiles,
  stylizedTag,
  vertical,
  heroLayoutType = {},
  authenticated = false,
  hasTempPass = false,
  provider = false,
  videoError = '',
  isShoppable = false,
  view,
}) => {
  const [hasStickyVideo, setHasStickyVideo] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const context = useContext(FeatureFlagContext);
  const isShoppingSection = sectionContainSlug(content.taxonomy.sections, 'shopping');
  const isMobile = Breakpoints.isSorM();

  const formattedArticleHeroPropValues = useMemo(() => ({
    authenticated,
    block,
    content,
    hasStickyVideo,
    hasTempPass,
    isChromeless,
    isLiveBlog,
    isMobile,
    isRecipe,
    isShoppingSection,
    isVideoPlaying,
    isPresentationStyleWide,
    heroLayoutType,
    provider,
    socialMediaProfiles,
    vertical,
    videoError,
    primaryMediaBannerEnabled,
    setStickyVideoFlag: setHasStickyVideo,
    /**
     * Sets the video play state to true.
     */
    setVideoPlayState: () => setIsVideoPlaying(true),
    pipAlignDesktop: isShoppable ? 'top' : 'bottom',
    pipAlignMobile: isShoppable ? 'top' : 'bottom',
  }), [
    authenticated, block, content, hasStickyVideo, hasTempPass, isChromeless, isLiveBlog,
    isMobile, isRecipe, isShoppingSection, isVideoPlaying, isPresentationStyleWide,
    heroLayoutType, provider, socialMediaProfiles, vertical, videoError, primaryMediaBannerEnabled,
    isShoppable,
  ]);

  const renderSponsor = useCallback(() => {
    const { nativeAd, source } = content;
    if (!nativeAd || !source) return null;

    const { organization: { externalUrl, primaryImage } = {} } = source;
    return (
      <div className="article-hero__sponsor">
        <Sponsored externalUrl={externalUrl} primaryImage={primaryImage} />
      </div>
    );
  }, [content]);

  const renderHeroFlag = useCallback((isBreakingNews) => {
    const { flag, taxonomy: { badge } } = content;
    const { 'article-badge': ARTICLE_BADGE } = context;
    const label = ARTICLE_BADGE ? badge?.name : flag;

    return isBreakingNews && !!label?.length ? (
      <ArticleHeroFlag
        isLiveBlog={isLiveBlog}
        vertical={vertical}
        label={label}
        heroLayoutType={heroLayoutType}
      />
    ) : null;
  }, [content, context, isLiveBlog, vertical, heroLayoutType]);

  const renderUnibrow = useCallback(() => {
    const {
      breakingNews, ecommerceEnabled, taxonomy, unibrow,
    } = content;
    const isBreakingNews = breakingNews && !isLiveBlog;

    return (
      <aside
        className={classNames(
          'article-hero__unibrow-grid layout-grid-item',
          {
            'grid-col-2-l': !heroLayoutType?.wide && !isRecipe,
            dn: isBreakingNews,
            'grid-col-11-xl grid-col-push-1-xl': heroLayoutType?.wide,
            'grid-col-11-m': isPresentationStyleWide,
            'grid-col-10-m grid-col-12-l grid-col-10-xl grid-col-push-1-m grid-col-push-0-l grid-col-push-1-xl': isRecipe,
          },
        )}
      >
        <Unibrow
          size="div"
          className={classNames(
            {
              'article-hero__tax-term': !heroLayoutType?.wide && !isRecipe,
              'article-hero__ecommerce-enabled': ecommerceEnabled,
              'article-hero__enhanced-opinion': isMSNBCDaily(vertical, taxonomy),
              'dn-print': isRecipe,
            },
          )}
          unibrow={unibrow}
          enableLogos
          hasDefaultTextStyle={heroLayoutType?.wide || isRecipe}
        />
      </aside>
    );
  }, [content, isLiveBlog, heroLayoutType, isRecipe, isPresentationStyleWide, vertical]);

  if (isShellArticle(content)) return null;

  const ecommerceEnabled = content?.ecommerceEnabled;
  const disclaimer = content?.ecommerceMetadata?.disclaimer;
  const hasDisclaimerText = !!disclaimer?.body[0]?.html?.trim();
  const shouldShowDisclaimer = hasDisclaimerText && ecommerceEnabled;

  const mediaPresentationStyle = getMediaPresentationStyle(formattedArticleHeroPropValues);
  const media = getMedia(formattedArticleHeroPropValues);

  const isBreakingNews = content.breakingNews && !isLiveBlog;
  const isLiveBreakingNews = content.breakingNews || isLiveBlog;

  const containerClasses = classNames(
    'article-hero__container',
    {
      [mediaPresentationStyle]: !isPresentationStyleWide,
      'article-hero__container--breaking': isBreakingNews,
      'article-hero__live-blog': isLiveBlog,
      'article-hero__live-breaking': isLiveBreakingNews,
      'article-hero__sticky-video': hasStickyVideo,
      'article-hero__presentation-wide': isPresentationStyleWide,
      'bg-none-print': isRecipe,
    },
  );

  return (
    <section className={containerClasses} data-test={block} data-testid="article-hero">
      <div
        className={classNames('article-hero__bg-container', {
          'article-hero__bg-container--recipe': isRecipe,
        })}
        data-authors={getDataAuthors(formattedArticleHeroPropValues)}
      >
        {!isLiveBlog && !isLiveBreakingNews && <div className="article-hero__divider" />}
        {shouldShowDisclaimer && (
          <div className="disclaimer-container">
            <DynamicDisclaimerDefault
              type={disclaimer?.type}
              verbiage={disclaimer?.body[0]?.html}
            />
          </div>
        )}
        <header
          className={classNames(
            'article-hero__header',
            'mh0-print',
            'layout-grid-container',
            {
              'article-hero__is-tagged': !!stylizedTag || isLiveBreakingNews,
              'pb0-print pl0-print mb0-print article-hero__header--recipe': isRecipe,
              'article-hero__is-breaking--wide': isLiveBreakingNews && isPresentationStyleWide,
            },
          )}
          data-test="article-hero__header"
        >
          {stylizedTag}
          {renderHeroFlag(isBreakingNews)}
          {!isLiveBlog && view !== VIEW.START_TODAY_APP && renderUnibrow()}
          {isRecipe && <TodayLogoForRecipePrint />}
          <ArticleHeroHeadline
            additionalClasses={additionalClasses.headline}
            dek={renderDek(formattedArticleHeroPropValues, isLiveBreakingNews)}
            isLiveBlog={isLiveBlog}
            isRecipe={isRecipe}
            isPresentationStyleWide={isPresentationStyleWide}
            heroLayoutType={heroLayoutType}
            path={path}
            timestamp={isRecipe ? <Timestamp content={content} /> : null} // for recipes only
            vertical={vertical}
          >
            {content.headline.primary}
          </ArticleHeroHeadline>
        </header>
      </div>
      <div className={classNames(
        'article-hero__media-holder',
        {
          'layout-grid-container': !isPresentationStyleWide,
          'dn-print': isRecipe,
          'article-hero__media-holder--compact': isLiveBreakingNews,
        },
      )}
      >
        {media && (
          <div
            className={classNames(
              'article-hero__media-container',
              {
                'layout-grid-item': !isPresentationStyleWide,
                'grid-col-10-xl grid-col-push-2-xl': !isLiveBlog && !heroLayoutType?.wide && !isRecipe && !isPresentationStyleWide,
                'grid-col-7_5-l grid-col-6-xl grid-col-push-2-xl': isLiveBlog && !heroLayoutType?.wide && !isPresentationStyleWide,
                'grid-col-11-m grid-col-10-xl grid-col-push-1-xl': heroLayoutType?.wide && !isPresentationStyleWide,
                'grid-col-10-m grid-col-12-l grid-col-10-xl grid-col-push-1-m grid-col-push-0-l grid-col-push-1-xl': isRecipe && !isPresentationStyleWide,
              },
            )}
          >
            {media}
          </div>
        )}
        {renderCaption(formattedArticleHeroPropValues)}
        {renderSponsor()}
      </div>
    </section>
  );
};

ArticleHero.propTypes = {
  additionalClasses: PropTypes.shape({
    headline: PropTypes.shape({}),
  }),
  content: primaryMediaPropType,
  isChromeless: PropTypes.bool,
  isLiveBlog: PropTypes.bool,
  isRecipe: PropTypes.bool,
  isPresentationStyleWide: PropTypes.bool,
  path: PropTypes.string,
  primaryMediaBannerEnabled: PropTypes.bool,
  socialMediaProfiles: socialPropType,
  stylizedTag: PropTypes.element,
  vertical: PropTypes.string.isRequired,
  heroLayoutType: heroLayoutTypePropType,
  authenticated: PropTypes.bool,
  hasTempPass: PropTypes.bool,
  provider: PropTypes.bool,
  videoError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  isShoppable: PropTypes.bool,
  view: PropTypes.string,
};

export default connect(mapStateToProps)(ArticleHero);
